var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-table",
                  {
                    ref: "table",
                    attrs: {
                      title: "안전순찰 결과 목록",
                      merge: _vm.grid.merge,
                      columns: _vm.grid.columns,
                      data: _vm.grid.data,
                      gridHeight: _vm.grid.height,
                      rowKey: "safetyCheckResultId",
                      columnSetting: false,
                      hideBottom: true,
                      filtering: false,
                      usePaging: false,
                    },
                    on: { headLinkClick: _vm.headLinkClick },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            props.row[col.key] === "SCR0000001"
                              ? [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "radio_button_unchecked",
                                      color: _vm.customColor(col, props.row),
                                    },
                                  }),
                                ]
                              : props.row[col.key] === "SCR0000005"
                              ? [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "close",
                                      color: _vm.customColor(col, props.row),
                                    },
                                  }),
                                ]
                              : props.row[col.key] === "SCR0000010"
                              ? [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "N/A",
                                      color: _vm.customColor(col, props.row),
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "suffixTitle" },
                      [
                        _c(
                          "font",
                          {
                            staticStyle: {
                              "font-size": "0.8em",
                              "font-weight": "300",
                            },
                          },
                          [
                            _c("q-chip", { attrs: { color: "green" } }),
                            _vm._v(" " + _vm._s("개선 건 없음") + " "),
                            _c("q-chip", { attrs: { color: "red" } }),
                            _vm._v(" " + _vm._s("개선 1건 이상") + "     "),
                            _c("q-icon", {
                              staticClass: "text-black",
                              attrs: {
                                name: "build",
                                size: "xs",
                                color: "blue-6",
                              },
                            }),
                            _vm._v(" " + _vm._s("완료되지 않음") + " "),
                            _c("q-icon", {
                              staticClass: "text-black",
                              attrs: {
                                name: "build",
                                size: "xs",
                                color: "red",
                              },
                            }),
                            _vm._v(" " + _vm._s("완료됨") + " "),
                          ],
                          1
                        ),
                        _vm._v("     "),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "prefixContent" },
                      [
                        _c(
                          "font",
                          {
                            staticStyle: {
                              "font-size": "0.8em",
                              "font-weight": "300",
                            },
                          },
                          [
                            _c("q-icon", {
                              staticClass: "text-black",
                              attrs: { name: "radio_button_unchecked" },
                            }),
                            _vm._v(" : 정상    "),
                            _c("q-icon", {
                              staticClass: "text-black",
                              attrs: { name: "close" },
                            }),
                            _vm._v(" : 이상    N/A : 해당없음    "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c(
            "c-dialog",
            {
              attrs: {
                param: _vm.popupOptions,
                returnData: { col1: _vm.data.safetyCheckId },
              },
            },
            [_vm._v(">")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }