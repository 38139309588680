<template>
  <div>
     <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title= "안전순찰 결과 목록"
            :merge="grid.merge"
            :columns="grid.columns"
            :data="grid.data"
            :gridHeight="grid.height"
            rowKey="safetyCheckResultId"
            :columnSetting="false"
            :hideBottom="true"
            :filtering="false"
            :usePaging="false"
            @headLinkClick="headLinkClick"
          >
            <template slot="suffixTitle">
              <font style="font-size:0.8em;font-weight:300;">
                <q-chip color="green" />
                  {{ '개선 건 없음' }}
                <q-chip color="red" />
                  {{ '개선 1건 이상' }}
                  &nbsp;&nbsp;&nbsp;
                <q-icon name="build" size='xs' color="blue-6" class="text-black"/> 
                  {{ '완료되지 않음' }}
                <q-icon name="build" size='xs' color="red" class="text-black"/> 
                  {{ '완료됨' }}
              </font>
                  &nbsp;&nbsp;&nbsp;
              <!-- <font style="font-size:0.8em;font-weight:300;" color="#C10015">
                ※ 결과 입력한 목록을 클릭시 개선 조회 및 추가를 할 수 있습니다. 단, 완료된 일자는 조회만 가능합니다.
              </font> -->
            </template>
            <template slot="prefixContent">
              <font style="font-size:0.8em;font-weight:300;">
                <q-icon name="radio_button_unchecked" class="text-black"/> : 정상 &nbsp;&nbsp;
                <q-icon name="close" class="text-black"/> : 이상 &nbsp;&nbsp;
                N/A : 해당없음 &nbsp;&nbsp;
              </font>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="props.row[col.key] === 'SCR0000001'">
                  <!-- class="cursor-pointer"  -->
                  <!-- @click="openPop(col, props.row)"  -->
                <q-icon 
                  name="radio_button_unchecked" 
                  :color="customColor(col, props.row)"/>
              </template>
              <template v-else-if="props.row[col.key] === 'SCR0000005'">
                <q-icon 
                  name="close" 
                  :color="customColor(col, props.row)"/>
              </template>
              <template v-else-if="props.row[col.key] === 'SCR0000010'">
                <q-icon 
                  name="N/A"
                  :color="customColor(col, props.row)"/>
              </template>
            </template>
          </c-table>
        </div>  
      </div>
      <c-dialog :param="popupOptions" :returnData="{ col1: data.safetyCheckId }">></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-check-result',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetyCheckId: '',
      }),
    },
    data: {
      type: Object,
      default: () => ({
        safetyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        vendorCd: '',  // 업체 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkMonth: '',  // 점검년월
        safetyCheckName: '',  // 점검명
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드

        safetyCheckResultModelList: [], // sheet
        safetyCheckUserModelList: [],

        detailList: [],
      }),
    },
    grid: {
      height: 'auto',
      columns: [],
      data: [],
    },
  },
  data() {
    return {
      year: '',
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sai.safetycheck.plan.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      resultItemDetail: '',
      currentMonth: '',
      lunarHolidays: [],
      today: '',
      lineCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || this.data.approvalStatusCd === 'ASC0000001'
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);

      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (this.today.substring(this.today, 0) === '0') {
        this.today = this.today.substring(this.today, 0)
      }
      // url setting
      this.insertUrl = transactionConfig.sai.safetycheck.plan.insert.url;
      this.updateUrl = transactionConfig.sai.safetycheck.plan.update.url;
      this.deleteUrl = transactionConfig.sai.safetycheck.plan.delete.url;
      this.completeUrl = transactionConfig.sai.safetycheck.plan.complete.url;
      this.resultItemDetail = selectConfig.sai.safetycheck.result.newItem.url;
      // code setting
      // list setting
      
      if (this.popupParam.safetyCheckId) {
        setTimeout(() => {
          let filterdata = this.data.detailList.filter( x => {
            return x.checkDate === this.$comm.getToday()
          });
          if (this.data.vendorCd === this.$store.getters.user.deptCd && filterdata.length <= 0) {
            this.headLinkClick(null, {key:'day' + this.today, label: this.today, day: this.$comm.getToday()}, null);
          }
        }, 1000);
      }

    },
    /* eslint-disable no-unused-vars */
    headLinkClick(props, col, index) {
      if (!this.data.safetyCheckId) {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '신규등록 후 일별 점검이 가능합니다.', /* 저장되었습니다. */
          type: 'warning', // success / info / warning / error
        });
        return;
      } 
      this.popupOptions.param = {
        col: col,
        data: this.data,
      };
      this.popupOptions.title = col.day + ' 안전순찰'
      this.popupOptions.target = () => 
        import(`${"./safetyCheckChecking.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.width = '90%';
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    closeResultPopup(returnData) {
      this.$refs['table'].selected = []

      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (returnData && returnData.col1) {
        this.data.safetyCheckId = returnData.col1;
        this.$emit('setKey', returnData.col1)
      } else {
        this.$emit('getDetail')
      }
    },
    openPop(col, row) {
      this.popupOptions.param = {
        safetyCheckId: this.data.safetyCheckId,
        sopMapId: this.data.sopMapId,
        safetyCheckResultId: row.safetyCheckResultId,
        checkItemName: row.checkItemName,
        key: col.key,
        day: col.day,
        checkStatusCd: this.data.checkStatusCd,
        data: row,
        disabled: col.completeIdx >=0 ? true : false,
      };
      this.popupOptions.title = col.day + ' 개선 상세'; 
      this.popupOptions.target = () =>
        import(`${"./safetyCheckImprPop.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '100%';
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    customColor(col, row) {
      let tableKey = this.$_.split(row['tableKeys'], ',');
      let color = 'green';
      if (tableKey && tableKey.length > 0) {
        this.$_.forEach(tableKey, item => {
          if (item === col.key) {
            color = 'red'
          } 
        })
      }
      return color;
    },
  }
};
</script>
